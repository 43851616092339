import React from 'react';
import {
    Typography, Box,
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import LinkIcon from '@mui/icons-material/Link';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';
import colors from '../../theme/colors';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    padding: '44px 136px 45px',
    minHeight: '195px',
    background: colors.burgundy,
    boxShadow: 'inset 0px -8px 20px rgba(0, 0, 0, 0.15)',
};
const customTextTypography = {
    fontFamily: 'Nunito',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '39px',
    letterSpacing: '0em',
    textAlign: 'left',
};

const customCircleRoundedIcon = {
    color: colors.lightGreen,
    fontSize: 'small',
    transform: 'scale(0.75)',
    marginRight: '8px',
};

const serviceIntroductionData = {
    title: 'Networking',
    firstParagraph: 'Servicio de consultoría en las redes de tu datacenter.',
    secondParagraph: 'Conectá tus sitios a la nube de forma segura.',
    gatsbyImg: (<StaticImage src="../../images/networking_icon.svg" alt="Networking" />),
    isButton: true,
    buttonText: 'Contactanos',
    buttonLinkTo: '/contact_us',
};

const casesOfUseData = {
    title: 'Definimos en conjunto',
    cases: [
        {
            image: (<StaticImage src="../../images/firewall_icon.svg" alt="Firewall" />),
            text: 'Configuración de firewalls',
        },
        {
            image: (<LinkIcon style={{ color: colors.green, height: '73px', width: '73px' }} />),
            text: 'Redundancia de enlaces',
        },
        {
            image: (<StaticImage src="../../images/tunnel_icon.svg" alt="Tunel" />),
            text: 'Túneles VPN entre sitios',
        },
        {
            image: (<StaticImage src="../../images/remote_access_icon.svg" alt="Acceso Remoto" />),
            text: 'Acceso remoto de los usuarios',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 3,
    customPadding: '39px 136px 78px',
};

const Networking = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <FunctionalityAndCasesContainer casesOfUseData={casesOfUseData} />
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.darkViolet,
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '48px 24px' }, ...infoWrapper })}>
                <Typography variant="h4" sx={{ ...customTextTypography, fontWeight: 700 }} mb={1}>
                    Ofrecemos servicios de consultoría
                </Typography>
                <Typography variant="h5" style={{ lineHeight: '32px' }}>
                    Entendiendo de qué proyecto de nube busques implementar, es posible que debas adaptar ciertos aspectos de la red, tu data center on-premise o sucursales, como VPNs, túneles, rutas y políticas, para migrar o adoptar servicios cloud.
                </Typography>
            </Box>
        </Box>
        <HomeConsultation />
    </Layout>
);

export default Networking;
